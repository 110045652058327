import { Link } from 'gatsby';
import { BubblesLayout } from 'layouts/bubblesLayout/bubblesLayout';
import * as pageClasses from 'pages/404.module.scss';
import * as React from 'react';
import { Helmet } from 'react-helmet';

const NotFoundPage: React.FC = () => (
  <main>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>ScheduleLab - 404</title>
      <meta charSet="utf-8" />
    </Helmet>

    <BubblesLayout>
      <article className={pageClasses.pageContent}>
        <p className={pageClasses.errorCode}>404</p>
        <h1 className={pageClasses.errorDescription}>Page not found</h1>
        <Link className="button-outline-white" to="/">
          Return to Home Page
        </Link>
      </article>
    </BubblesLayout>
  </main>
);

export default NotFoundPage;
