import { BubbleVisual } from '@schedulelab/components/bubbleVisual/bubbleVisual';
import { Footer } from 'components/footer/footer';
import { NavigationBar } from 'components/navigationBar/navigationBar';
import * as bubblesLayoutClasses from 'layouts/bubblesLayout/bubblesLayout.module.scss';
import React, { PropsWithChildren } from 'react';

export const BubblesLayout: React.FC<PropsWithChildren<unknown>> = (props) => {
  return (
    <BubbleVisual bubbleCount={25}>
      <NavigationBar />
      <div className={bubblesLayoutClasses.pageLayout}>
        <article className={bubblesLayoutClasses.pageContent}>{props.children}</article>
        <Footer />
      </div>
    </BubbleVisual>
  );
};
